import { Text } from "../Text";
import { TAnyKeyValue } from '../Core';

export type CallbackNames<T extends TAnyKeyValue> = { [K in keyof T]: T[K] extends (Function | undefined) ? K : never }[keyof T];
export type CallbackProps<T> = Pick<T, CallbackNames<T>>;

export type TEvents<T> = Readonly<Required<CallbackProps<T>>>;

import * as R from 'ramda';

export class EventArgs {
    constructor() {}
}

export class EventManager<P extends TAnyKeyValue, S> {

    public readonly events: TEvents<P>;

    constructor(private readonly component: React.Component<P, S>) {
        this.events = this.createEvents(component)
    }

    protected createEvents(component: React.Component<P, S>): TEvents<P> {
        // @ts-ignore
        return new Proxy(component.props, {
            get: (props, eventName: string) => {
                const event = props[eventName];
                return typeof event === "function" ? event : () => {
                    this.ignoredWarning(component, eventName as CallbackNames<P>);
                };
            }
        });
    }

    protected ignoredWarning(component: React.Component<P, S>, eventName: CallbackNames<P>) {
        console.warn(`Event "${component.constructor.name}.${eventName}" was ignored`);
    }
}