export module Browser {

    export function isInternetExplorer(): boolean {
        const { navigator: { userAgent } } = window;
        return userAgent.indexOf('MSIE ') > 0 || userAgent.indexOf('Trident/') !== -1;
    }

    export function isEdge(): boolean {
        const { navigator } = window;
        return navigator.appName === "Netscape" && navigator.appVersion.indexOf("Edge") !== -1;
    }

    export function isInternetExplorerOrEdge(): boolean {
        return isInternetExplorer() || isEdge();
    }

    // chrome aj chromium
    export function isChrome(): boolean {
        const { userAgent } = navigator;
        return userAgent.search("Chrome") !== -1;
    }

    export function isSafari(): boolean {
        const { userAgent } = navigator;
        return  userAgent.search("Safari") !== -1 && !isChrome();
    }

    export function isFirefox(): boolean {
        const { userAgent } = navigator;
        return !!userAgent.match(/Firefox\/([0-9]+)\./);
    }

    export function isOpera(): boolean {
        return !!navigator.userAgent.match(/Opera|OPR\//);
    }

    export function isVivaldi(): boolean {
        return navigator.userAgent.indexOf("Vivaldi") !== -1;
    }

    export function isSearchBot(): boolean {
        const { userAgent } = navigator;
        return !!userAgent.match(/(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/i)
    }
}

export module OS {

    export function isWindows() {
        return navigator.platform.indexOf('Win') !== -1;
    }

    export function isiOS(): boolean {
        const { userAgent } = navigator;
        return !!userAgent.match(/(iPad|iPhone|iPod)/g);
    }

    export function isMacOS(): boolean {
        return navigator.platform.toUpperCase().indexOf('MAC') !== -1;
    }

    export function isAppleOS(): boolean {
        return isiOS() || isMacOS();
    }

    export function isAndroid(): boolean {
        const { userAgent } = navigator;
        return /(android)/i.test(userAgent);
    }

    // klasicky dektopovy / serverovy linux
    export function isNonBionicLinux(): boolean {
        const { userAgent } = navigator;
        return !!userAgent.match(/linux/i) && !isAndroid();
    }

    export function isLinuxOrAndroid(): boolean {
        return isNonBionicLinux() || isAndroid();
    }

    export function isWebOS(): boolean {
        const { userAgent } = navigator;
        return !!userAgent.match(/WebOS/i)
    }

    export function isFreeBSD(): boolean {
        const { userAgent } = navigator;
        return !!userAgent.match(/FreeBSD/i)
    }

    export function isBSD(): boolean {
        const { userAgent } = navigator;
        return !!userAgent.match(/BSD/i)
    }

    export function isQNX(): boolean {
        const { userAgent } = navigator;
        return !!userAgent.match(/QNX/i)
    }

    export function isSolaris(): boolean {
        const { userAgent } = navigator;
        return !!userAgent.match(/SunOS/i)
    }

}