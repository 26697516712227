import * as moment from 'moment';
import * as R from 'ramda';
import * as stdio from 'extsprintf';

export class ExplicitDateFormatter {
    
    constructor(
        readonly dateTimeFormat: string,
        readonly dateFormat: string,
        readonly timeFormat: string,
        readonly timeFormatWithSec: string,
        readonly timeZoneSuffix?: string
    ) {}

    protected formatTo = (format: string, timeZoneInfo: string = '') => (input?: moment.MomentInput, defaultValue?: string) => (
        ((R.isNil(input) || R.isEmpty(input))
            ? (defaultValue || format)
            : moment(input).format(format)) + timeZoneInfo
    )

    /** @description formátuje dátum a čas */
    asDateTime = this.formatTo(this.dateTimeFormat, this.timeZoneSuffix);
    
    /** @description formátuje len dátum */
    asDate = this.formatTo(this.dateFormat);

    /** @description formátuje len čas bez sekúnd */
    asTime = this.formatTo(this.timeFormat, this.timeZoneSuffix);

    /** @description formátuje len čas so sekundami */
    asTimeWithSec = this.formatTo(this.timeFormatWithSec, this.timeZoneSuffix);
}

export const removeSeconds = (timeFormatWithSec: string, timeDelimiter: string) => (
    R.dropLast(1, timeFormatWithSec.split(timeDelimiter)).join(timeDelimiter) as string
)

export const getTimeZoneInfo = (timeDelimiter: string) => {
    const tz = (new Date ()).getTimezoneOffset() * -1;
    // existuju aj krajiny ktore maju casove pasmo posunute aj o 30 min, preto som nehal aj minuty
    const hours = Math.floor(tz / 60), mins = tz % 60; 
    return stdio.sprintf(`${hours > 0 ? '+' : ''}%02d%s%02d`, hours, timeDelimiter, mins)
}

export const currentTimeZone = getTimeZoneInfo(':');

export class DateFormatter extends ExplicitDateFormatter {
    constructor(dateFormat: string, timeFormatWithSec: string, dateTimedelimiter: string = ' ', timeZoneSuffix = '', timeDelimiter: string = ':') {
        super(
            (dateFormat + dateTimedelimiter + timeFormatWithSec),
            dateFormat,
            removeSeconds(timeFormatWithSec, timeDelimiter),
            timeFormatWithSec,
            timeZoneSuffix
        );
    }
}

export module DateTime {
    export const now = () => new Date();

    export const today = () => {
        const now = DateTime.now();
        return new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
    }
}

export type TDateFormatFunc = (dateTime: moment.MomentInput) => string;

export module DateFormat {
    /** @description formát dátumu a času platný na SK napr.: 30.12.2000 15:55:20 (DD.MM.YYYY HH:mm:ss) */
    export const Default = new DateFormatter('DD.MM.YYYY', 'HH:mm:ss');

    /** @description (skratka pre Default.asDateTime) formátuje dátum a čas */
    export const asDateTime = Default.asDateTime;
    
    /** @description (skratka pre Default.asDate) formátuje len dátum */
    export const asDate = Default.asDate;

    /** @description (skratka pre Default.asTime) formátuje len čas bez sekúnd */
    export const asTime = Default.asTime;

    /** @description (skratka pre Default.asTimeWithSec) formátuje len čas so sekundami */
    export const asTimeWithSec = Default.asTimeWithSec;

    /** @description formát dátumu a času podľa GMT napr: Mon, 30 12 2000 15:55:20 GMT (ddd, DD MM YYYY HH:mm:ss GMT) */
    export const GMT = new DateFormatter('ddd, DD MM YYYY', 'HH:mm:ss', ' ', ` GMT`);

    /** @description formát dátumu a času podľa GMT s časovou zónou na konci napr: Mon, 30 12 2000 15:55:20 GMT+02:00 */
    export const GMTTimeZone = new DateFormatter('ddd, DD MM YYYY', 'HH:mm:ss', ' ', ` GMT${currentTimeZone}`);

    /** @description formát dátumu a času štandartne používaný v databázach napr: 2000-12-30 15:55:20 (YYYY-MM-DD HH:mm:ss) */
    export const Global = new DateFormatter('YYYY-MM-DD', 'HH:mm:ss');

    /** @description formát dátumu a času podľa ISO8601: 2000-12-30T15:55:20 (YYYY-MM-DDTHH:mm:ss) */
    export const ISO8601 = new DateFormatter('YYYY-MM-DD', 'HH:mm:ss', 'T');

    /** @description formát dátumu a času podľa ISO8601 so Z (zulu) na konci: 2000-12-30T15:55:20Z (YYYY-MM-DDTHH:mm:ssZ) */
    export const ISO8601UTC = new DateFormatter('YYYY-MM-DD', 'HH:mm:ss', 'T', 'Z');

    /** @description formát dátumu a času podľa ISO8601 s časovou zónou na konci: 2000-12-30T15:55:20+02:00 */
    export const ISO8601TimeZone = new DateFormatter('YYYY-MM-DD', 'HH:mm:ss', 'T', currentTimeZone);
}