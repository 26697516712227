import * as $ from "jquery";
import { DomModule } from '@core/DomModule';
import { TFormatter } from '@core/Application';
import { TEditorInitializers } from '@core/Editors';

type TElement = JQuery<HTMLElement> & {
    isLoaded: boolean
};

export type TEditModuleProps = {
    readonly selector: string;
    readonly editors: TEditorInitializers;
}

export class EditModule extends DomModule {
    protected readonly noteFormatter = this.getElement(this.props.selector);

    constructor(protected readonly props: TEditModuleProps) {
        super();
    }

    protected getElement(selector: string) {
        const element = $(selector) as TElement;
        element.isLoaded = element[0] != undefined;
        return element;
    }

    init = () => {
        if (this.noteFormatter.isLoaded) {
            const { props: { editors }, noteFormatter } = this;
            if (noteFormatter) {
                const initEditor = editors['editorMD'];
                if (typeof initEditor === "function") {
                    initEditor();
                }
            }
        }
    }
}