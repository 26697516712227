
export module Html {

    export function escape(input: string) {
        let div = document.createElement('div')
        div.innerText = input;
        return div.innerHTML;
    }

    export function stripTags(input: string) {
        let div = document.createElement('div')
        div.innerHTML = input;
        return document.all ? div.innerText : div.textContent;
    }
    
}