import * as $ from "jquery";
import { DomModule } from '../../Core/DomModule';
import { Uri } from "@lib/Core";

export class AutoUriModule extends DomModule {
    
    private handler: (() => void) | null = null;

	protected createModule = (nameInput: JQuery<HTMLElement>, uriInput: JQuery<HTMLElement>) => () => {
		if (nameInput.val() != '' && uriInput.val() == '') {
			uriInput.val(Uri.create(nameInput.val() as string));
		}
	};
	
    protected initUriFields = (nameInput: JQuery<HTMLElement>, uriInput: JQuery<HTMLElement>) => {
        if (this.handler) {
            uriInput.off('click focus', this.handler);
        }
        this.handler = this.createModule(nameInput, uriInput);
        uriInput.on('click focus', this.handler);
    }

    init = () => {
		const nameInput = $("#name"), uriInput = $("#uri");
        if (nameInput[0] && uriInput[0]) {
            this.initUriFields(nameInput, uriInput);
        }
    }
}