import * as $ from "jquery";
import { DomModule } from '@core/DomModule';
import { TFormatter } from '@core/Application';
import { TEditorInitializers } from '@core/Editors';
import { safeAccess, Accessor } from '@lib/Core';

type TElement= JQuery<HTMLElement> & {
    isLoaded: boolean
};

export type TArticleEditModuleProps = {
    readonly storage: Storage;
    readonly formatters: Map<number, TFormatter>;
    readonly editors: TEditorInitializers;
}

export class ArticleEditModule extends DomModule {
    protected readonly formatterSelect = this.getElement('#formatter_id');
    protected readonly editFormatterIdElement = this.getElement('#editFormatterId');

    constructor(protected readonly props: TArticleEditModuleProps) {
        super();
    }

    protected getElement(selector: string) {
        const element = $(selector) as TElement;
        element.isLoaded = element[0] != undefined;
        return element;
    }

    protected get editFormatterId() {
        const { editFormatterIdElement } = this;
        return (
            safeAccess(editFormatterIdElement).getInt(Accessor.func('val'), null)
        );
    }

    protected get formatterId() {
        const { props: { storage } } = this;
        return (
            this.editFormatterId ||
            safeAccess(storage).getInt(Accessor.func('getItem', 'formatterId'), null) ||
            1
        );
    }

    protected set fromatterId(formatterId: string) {
        const { props: { storage } } = this;

        storage.setItem('formatterId', formatterId);
        window.location.reload();
    }

    protected get formatter(): TFormatter | null {
        const { props: { formatters } } = this;
        //@ts-ignore
        return formatters.get(this.formatterId) || null;
    }

    protected handleChangeFormatterSelect = () => (
        this.fromatterId = this.formatterSelect.val() as string
    )

    init = () => {
        if (this.formatterSelect.isLoaded) {
            const { props: { editors }, formatter } = this;
            if (formatter) {
                this.formatterSelect.val(formatter.id);
                this.formatterSelect.off('change').on('change', this.handleChangeFormatterSelect);
                const initEditor = editors[formatter.uri];
                if (typeof initEditor === "function") {
                    initEditor();
                }
            }
        }
    }
}