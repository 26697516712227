import { TAnyKeyValue, Arr } from './Core';
import { Option, TOption } from './Option';
import { Text } from '../Text';

type TSource = TAnyKeyValue;
type TNumberParser = (input: string) => number;

export module AccessPath {

    export type TFunc = {
        kind: "func";
        name: string;
        thisArg?: any;
        args: any[];
    }

    export type TIndex = {
        kind: "index";
        name: string;
        index: any;
    }

    export type TAccessor = string | TFunc | TIndex;

    export type TAccessPath = TAccessor | TAccessor[];

    const getValue = (source: TSource, accessor: AccessPath.TAccessor) => {
        if (typeof accessor === "string") {
            return source[accessor];
        } else {
            switch (accessor.kind) {
                case "func": {
                    const func = source[accessor.name] as Function;
                    return func.apply(
                        'thisArg' in accessor ? accessor.thisArg : source,
                        accessor.args
                    );
                }
                case "index": {
                    return source[accessor.name][accessor.index];
                }
            }
        }
    }

    function unify(accessPath: TAccessPath): TAccessor[] {
        if (typeof accessPath === "string") {
            return accessPath.split('.');
        } else if (Array.isArray(accessPath)) {
            return Arr.flat(
                accessPath.map(accessor => typeof accessor === "string" ? unify(accessor) : accessor)
            ) as TAccessor[]
        } else if (
            accessPath['kind'] === "func" ||
            accessPath['kind'] === "index"
        ) {
            return [accessPath as TFunc | TIndex];
        } else {
            //@ts-ignore
            throw `Invalid path: "${(accessPath.toString())}"`;
        }
    }

    const toProp = (h : TAccessor) => typeof h === "string" ? h : h.name
    
    function tryGetArray<R = any>(source: TSource, path: AccessPath.TAccessor[]): TOption<R> {
        if (path.length > 1) {
            const [h, ...t] = path;
            return source instanceof Object && toProp(h) in source ? tryGetArray(getValue(source, h), t) : { kind: "None" };
        } else if (path.length === 1) {
            const [h] = path;
            return source instanceof Object && toProp(h) in source ? { kind: "Some", value: getValue(source, h) } : { kind: "None" }
        } else {
            return { kind: "Some", value: source } as TOption<R>;
        }
    }

    export const get = <T = any>(source: TSource, path: TAccessPath) => (
        AccessPath.getOr<T, undefined>(source, path, undefined)
    );

    export const tryGet = <R = any>(source: TSource, path: TAccessPath) => (
        tryGetArray<R>(source, unify(path))
    );
    
    export const getOr = <R = any, D = R>(source: TSource, path: TAccessPath, defaultValue: D) => (
        Option.toDefaultValue<R, D>(tryGet<R>(source, path), defaultValue)
    );

    export const getOrNull = <T = any>(source: TSource, path: TAccessPath) => (
        AccessPath.getOr<T, null>(source, path, null)
    );

    const parseNumberFactory = (parser: TNumberParser) => (
        <D = number>(source: TSource, path: TAccessPath, defaultValue: D) => {
            const optionValue = AccessPath.tryGet<string>(source, path);
            return (
                optionValue.kind === "Some" &&
                Text.isNumeric(optionValue.value)
                    ? parser(optionValue.value)
                    : defaultValue
            );
        }
    );

    export const getInt = parseNumberFactory(parseInt);

    export const getFloat = parseNumberFactory(parseFloat);
}

export module Accessor {

    export const func = (name: string, ...args: any[]): AccessPath.TFunc => ({
        kind: "func",
        name,
        args
    });

    export const funcWith = (name: string, thisArg: any, ...args: any[]): AccessPath.TFunc => ({
        kind: "func",
        name,
        thisArg,
        args
    });

    export const index = (name: string, index: any): AccessPath.TIndex => ({
        kind: "index",
        name,
        index
    });
}

export type TAccessPath = AccessPath.TAccessPath;

class SafeAccess {
    constructor(readonly source: TSource) {}

    get = <T = any>(path: TAccessPath) => AccessPath.get<T>(this.source, path);

    tryGet = <T = any>(path: TAccessPath) => AccessPath.tryGet<T>(this.source, path);

    getOr = <T = any, D = any>(path: TAccessPath, defaultValue: D) => AccessPath.getOr<T, D>(this.source, path, defaultValue);

    getOrNull = <T = any>(path: TAccessPath) => AccessPath.getOrNull<T>(this.source, path);  

    getInt = <D = number>(path: TAccessPath, defaultValue: D) => AccessPath.getInt<D>(this.source, path, defaultValue);

    getFloat = <D = number>(path: TAccessPath, defaultValue: D) => AccessPath.getFloat<D>(this.source, path, defaultValue);
}

export const safeAccess = (source: TAnyKeyValue) => new SafeAccess(source);