import { TAnyKeyValue } from '../../Lib/Core/Core';
export abstract class DomModule {
    abstract init(): void;
}

export type TDomModule = [ string, TAnyKeyValue? ];

export module DomModules {
    export function init(domModules: TDomModule[]) {
        domModules.forEach(([module, ...params]) => {
            const moduleFullName = `${module}Module`;
            const lib = require(`../../DomModules/${moduleFullName}`);
            const domModule = new lib[moduleFullName](...params) as DomModule;
            domModule.init();
        });
    }
}