import { safeAccess } from '../../Lib/Core/SafeAccess';
type TUser = Readonly<{
    id: number | string | null;
    isLoggedIn: boolean;
    isAdmin: boolean;
    auth: {
        token: string | null;
        token_time: string | null;
    };
    displayName: string;
}>;

type TKey = {
    UID: string;
    Token: string;
    TokenTime: string;
}

export type TUserSession = TUser & Readonly<{
    key(): TKey;
}>

export module UserSessionFactory {
    export function createSession(user: TUser) {
        return ({
            ...user,
            key() {
                return {
                    UID: user.id,
                    Token: user.auth.token,
                    TokenTime: user.auth.token_time
                }
            }
        })
    }
}