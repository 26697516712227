import { TOption, Option } from './Option';
import { nil } from './Core';

export module Json {
    export const parse = JSON.parse;

    export const stringify = JSON.stringify;

    export const tryParse = <T = any>(text: string | nil, reviver?: (this: any, key: string, value: any) => any): TOption<T> => {
        try {
            return Option.some<T>(Json.parse(text as string, reviver));
        } catch (e) {
            return Option.none();
        }
    };

    export const prettify = <T = any>(input: string | T, numberOfSpaces: number = 4) => (
        Json.stringify(
            typeof input === 'string' ? Json.parse(input) : input,
            null,
            numberOfSpaces
        )
    );

    export const tryPrettify = <T = any>(input: string | T, numberOfSpaces: number = 4): TOption<string> => {
        try {
            return Option.some<string>(Json.prettify<T>(input, numberOfSpaces));
        } catch (e) {
            return Option.none();
        }
    };
}