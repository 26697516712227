import { DomModule } from '@core/DomModule';
import { Application } from '@core/Application';

export class TagModule extends DomModule {
    init() {
        const tags = document.querySelectorAll('.Tag');
        tags.forEach(e => {
            const tag = e as HTMLElement;
            tag.addEventListener("click", event => {
                window.location.href = `${Application.root}/tag/${encodeURIComponent(tag.innerText)}`
            });
        });
    }
}