import { DomModule } from '@core/DomModule';

export class PrintModule extends DomModule {

    private createPrintFrame(src: string) {
        const frame = document.createElement('iframe');
        frame.width = '0';
        frame.height = '0';
        frame.src = src;
        frame.id = "printFrame";
        return frame;
    }

    private get printUrl() {
        return `${window.location.href}?nolayout=1`;
    }

    get printContent(): Window {
        const printFrame = document.getElementById("printFrame") as HTMLIFrameElement;
        if (printFrame && printFrame.contentWindow) {
            return printFrame.contentWindow;
        } else {
            const printFrame = this.createPrintFrame(this.printUrl);
            document.body.appendChild(printFrame);
            return this.printContent;
        }
    }
    
    private handlePrintClick = () => {
        this.printContent.print();
    }

    init() {
        document.querySelectorAll(".Print").forEach(button => {
            const anchor = button as HTMLAnchorElement;
            anchor.onclick = this.handlePrintClick;
        });
    }
}