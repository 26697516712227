import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { DomModule } from './DomModule';
import * as R from 'ramda';

type TSingleReactModuleBaseProps = {

}

type TIdProps = TSingleReactModuleBaseProps & {
    id: string;
    query?: undefined;
}

type TQueryProps = TSingleReactModuleBaseProps & {
    id?: undefined;
    query: string;  
}

export type TSingleReactModuleProps = TIdProps | TQueryProps;

export abstract class SingleReactModule<T extends HTMLElement = HTMLElement> extends DomModule {

    readonly container: T | null;

    protected constructor(readonly props: TSingleReactModuleProps) {
        super();
        this.container = (
            props.id
                ? document.getElementById(props.id) as T | null
                : document.querySelector(props.query as string) as T | null
        )
    }

    init() {
        const { container } = this; 
        if (container) {
            const element = this.render();
            if (element) {
                ReactDOM.render(element, container);
            }
        }
    }

    protected abstract render(): JSX.Element | null | undefined;
}