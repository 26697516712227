import { EventManager, TEvents } from './EventManager';
import { ReactAsync } from './ReactAsync';
import * as React from 'react';

export module Reactive {

    export class Component<P = {}, S = {}, SS = any> extends React.Component<P, S, SS> {
        
        protected readonly events: TEvents<P>;
    
        constructor(props) {
            super(props);
            const eventManager = new EventManager<P, S>(this);
            this.events = eventManager.events;
        }
    
        setStateAsync<K extends keyof S>(state: ((prevState: Readonly<S>, props: P) => (Pick<S, K> | S)) | (Pick<S, K> | S)) {
            return ReactAsync.setState<K, P, S>(state, this);
        }
    
        fillState<K extends keyof S>(filler: (value: any, key: string) => any) {
            return ReactAsync.fillState<K, P, S, SS>(filler, this);
        }
    
        clearState<K extends keyof S>() {
            return ReactAsync.clearState<K, P, S, SS>(this);
        }
    
    }
    
}