import { DomModule } from '@core/DomModule';
import { Age } from '@lib/Core';
import { Text } from "@lib/Text";
import { DateTime, RelativeDate } from '@core/DateTime';

export class DateModule extends DomModule {

    protected setValueFor(selector: string, value: string) {
        let elements = document.querySelectorAll(selector);
        elements.forEach(element => element.innerHTML = value);
    }

    protected prettifyDates() {
        const articleDates = document.querySelectorAll('.ArticleDate');
        articleDates.forEach(e => {
            const element = e as HTMLElement;
            const dateTime = DateTime(element.dataset.dateSource);
            element.setAttribute('title', dateTime.format('dddd, Do MMMM YYYY, HH:mm:ss'));
            const textElement = element.querySelector('.text') as HTMLElement;
            textElement.innerText = RelativeDate.prettify(dateTime.fromNow());
        });
        const articleDetailDates = document.querySelectorAll('.ArticleDetailDate');
        articleDetailDates.forEach(e => {
            const element = e as HTMLElement;
            const dateTime = DateTime(element.dataset.dateSource);
            element.setAttribute('title', RelativeDate.prettify(dateTime.fromNow()));
            const textElement = element.querySelector('.text') as HTMLElement;
            textElement.innerText = Text.replaceAll(`v ${dateTime.format('dddd, Do MMMM YYYY, HH:mm:ss')}`, {
                'v sobota': 'v sobotu',
                'v nedeľa': 'v nedeľu',
            });
        });
    }

    protected assignYears() {
        const age = Age.fromBirthdayISOString("1982-12-21");
        [
            [".age", age],
            [".skills", age - 14],
            [".prax", age - 25],
            [".react", age - 32],
            [".current-year", new Date().getFullYear()]
        ].forEach(
            ([selector, value]) => (
                this.setValueFor(selector as string, value.toString())
            )
        );
    }

    init() {
        this.prettifyDates();
        this.assignYears();
    }
}