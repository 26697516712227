import './assets/Product.styl';

import * as React from 'react';
import { TProductData } from './Model';
import { Application } from '@core/Application';
import { Uri } from '@lib/Core';
import * as DOMPurify from 'dompurify';

type TProduct = TProductData & {
    index: number;
}

export class Product extends React.Component<TProduct> {

    render() {
        
        const {
            index, name: caption, note, cat_name: categoryName,
            cat_uri: categoryUri, uri, url, download_link, tags
        } = this.props;

        const fullImagePath = (
            `${Application.root}/img/product/${uri}_product.jpg`
        );

        const resizedImagePath = (
            `${Application.root}/img/product/crop/${uri}_product.jpg`
        );
        
        return (
            <article className="Product col s12 m6 l4">
                <div className={`card work-item ${categoryUri} all`}>
                    <header className="card-image waves-effect waves-block waves-light">
                        <a href={`#popup-${index}`} className="activator">
                            <img src={resizedImagePath} alt="" />
                        </a>
                    </header>
                    <footer className="card-content">
                        <div>
                            <h1 className="card-title grey-text text-darken-4">
                                <a href={`#popup-${index}`} className="activator">
                                    <span>{caption}</span>
                                </a>
                                <a href={fullImagePath} data-fancybox="portfolio">
                                    <i className="material-icons right">zoom_out_map</i>
                                </a>
                                <a href={`#popup-${index}`} className="activator">
                                    <i className="material-icons right">info</i>
                                </a>
                            </h1>
                            {tags && 
                                <div className="ProductTags">{tags.split(',').map(tag => <span key={tag} className="tag cyan darken-2 mmr5">{tag.trim()}</span>)}</div>
                            }
                            <a target="blank" href={url} className="domain blue-text text-darken-2 left-column">
                                {Uri.toDomain(url)}
                            </a>
                            <span className="category blue-text text-darken-2 right-column">
                                {categoryName}
                            </span>
                        </div>
                    </footer>
                    <section id={`popup-${index}`} className="popup-box mfp-fade mfp-hide">
                        <div className="content">
                            <header className="image">
                                <a className="zoomed-portfolio" href={fullImagePath} data-fancybox="zoomed-portfolio">
                                    <img src={resizedImagePath} alt="" />
                                    <i className="material-icons right">zoom_out_map</i>
                                </a>
                            </header>
                            <footer className="desc">
                                <div className="category">{categoryName}</div>
                                <h4>{caption}</h4>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(note, {
                                            KEEP_CONTENT: false,
                                        }),
                                    }}
                                />
                                {tags && 
                                    <div className="ProductTags">{tags.split(',').map(tag => <span key={tag} className="tag cyan darken-2 mmr5">{tag.trim()}</span>)}</div>
                                }
                                {url &&
                                    <a
                                        target="_blank"
                                        href={url}
                                        className="Button View btn waves-effect waves-light"
                                    >
                                        Zobraziť projekt
                                    </a>
                                }
                                {download_link &&
                                    <a
                                        target="_blank"
                                        href={download_link}
                                        className="Button Url btn waves-effect waves-light"
                                    >
                                        Stiahnuť
                                    </a>
                                }
                            </footer>
                        </div>
                    </section>
                </div>
            </article>  
        );
    }

}