import * as React from 'react';
import { Portfolio } from '@components/Portfolio';
import { Application } from '@core/Application';
import { SingleReactModule } from '@core/DomModule/ReactModule';

export class PortfolioModule extends SingleReactModule {

    constructor() {
        super({ id: "portfolio" })
    }

    render() {
        const { portfolio } = Application.data;
        return (
            portfolio
                ?
                    <Portfolio
                        caption={"Výber projektov"}
                        note={<>Dal som sem len pár svojich projektov na, ktorých som robil v poslednej dobe, vo voľnom čase a ktoré sú niečim zaujímavé. Teraz robím na dlhodobom projekte (CRM) pre jednu poisťovňu, ktorá má ako zákazníka poisťovňu. Viac info k tomu dať nemôžem, nakoľko som podpisoval mlčanlivosť. Predtým som robil na rovnako dlhodobom projekte ZoneCode pre môjho bývalého zamestnávateľa: <a target="_blank" href="http://www.infotech.sk/">Infotech s.r.o.</a> V minulosti (naposledy niekedy pred rokom 2015) som dokončil veľmi veľa webov a eshopov (možno okolo 100) pre reklamné agentúry 4ME Media, +421 Studio, Orflex pochopiteľne tvorbe webových prezentácií sa vo veľkom venovať určite nechcem, keďže tie dokáže v dnešnej dobe vytvoriť hociaký študent. Chcem sa do budúcna venovať SPA alebo komplexných aplikácií.</>}
                        products={portfolio}
                    />
                :
                    null
        )
    }
}