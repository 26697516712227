export module Base64 {
	
	// pracuje s base64 reťazcami (bez headeru)
	export module RawData {
		export function toBlob(base64RawData: string, mimeType = '', sliceSize = 512) {
			const byteCharacters = atob(base64RawData);
			const uint8Arrays = [];
			for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
				const slice = byteCharacters.slice(offset, offset + sliceSize);
				const arr = new Array(slice.length);
				for (let i = 0; i < slice.length; i++) {
					arr[i] = slice.charCodeAt(i);
				}
				uint8Arrays.push(new Uint8Array(arr) as never);
			}
			return new Blob(uint8Arrays, { type: mimeType });
		}
		
		// konvertuje b64 reťazec na dátový objekt (typu blob) uložený v pamäti a vracia urlku ukazujúcu na toto miesto v pamäti
		export function toBinaryDataObject(base64RawData: string, mimeType: string) {
			const blob = toBlob(base64RawData, mimeType);
			return window.URL.createObjectURL(blob);
		}
	}

	// pracuje s kompletnýmu base64 dataURLkami (obsahujúcimi header)
	export module DataUrl {
		const dataUrlId = 'data:';
		const base64Id = ';base64';

		export function toB64Header(dataUrl: string) {
			const end = dataUrl.indexOf(',');
			return end === -1 ? null : dataUrl.substring(0, end);
		}
	
		export function toB64MimeType(dataUrl: string) {
			const linkHeader = toB64Header(dataUrl);
			return linkHeader && dataUrl.startsWith(dataUrlId) && linkHeader.endsWith(base64Id)
				? linkHeader.substring(dataUrlId.length, linkHeader.indexOf(base64Id))
				: null;
		}

		export const toB64RawData = (dataUrl: string) => (
			dataUrl.substr(dataUrl.indexOf(',') + 1)
		)

		export function toBlob(dataUrl: string) {
			const mimeType = toB64MimeType(dataUrl);
			return mimeType ? Base64.RawData.toBlob(dataUrl, mimeType) : null;
		}

		// konvertuje b64 dataURL na dátový objekt (typu Blob) uložený v pamäti vracia urlku ukazujúcu na toto miesto v pamäti
		export function toBinaryDataObject(dataUrl: string) {
			const mimeType = toB64MimeType(dataUrl);
			if (mimeType === null) {
				return null;
			} else {
				const blob = Base64.RawData.toBlob(toB64RawData(dataUrl), mimeType);
				return window.URL.createObjectURL(blob);
			}

		}
	}
}

// objekt očakáva ako parameter konštruktoru dátovú URLku alebo typ Blob a vracia object url-ku ukazujúcu na dáta v pamäti
export class DataLink {
	private string: string | null = null;

	constructor(readonly dataLinkOrBlob: string | Blob) {}

	toString() {
		//lazy vytváranie
		if (this.string === null) {
			if (this.dataLinkOrBlob instanceof window.Blob) {
				// ak je na vstupe blob vytvorí objectUrl ukazujúcu na blob
				this.string = window.URL.createObjectURL(this.dataLinkOrBlob as Blob);
			} else {
				// ak je na vstupe blob vytvorí objectUrl ukazujúcu na blob
				const dataObject = Base64.DataUrl.toBinaryDataObject(this.dataLinkOrBlob as string);
				this.string = dataObject === null
					? this.dataLinkOrBlob as string
					: dataObject
			}
		}
		return this.string;
	}

	// zruší ukazovanie na miesto v pamäti aby mohol neskôr GC toto miesto vyčistiť
	release() {
		if (this.string && URL.revokeObjectURL) {
			URL.revokeObjectURL(this.string);
			this.string = null;
		}
	}
}