import * as React from 'react';
import * as classNames from 'classnames';
import * as DOMPurify from 'dompurify';
import { Reactive } from '@lib/Core.Reactive';
import { TComment } from './Model/Entities/TComment';
import { TServices } from '../../Core/Application';
import { DateFormat } from '@lib/Core/DateFormat';
import { Option } from '@lib/Core';

type TCommentProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    comment: TComment;
    onReply: (comment: TComment) => void;
    onRemove: (comment: TComment) => void;
    isAllowedModeration: boolean;
    isReplyingEnabled: boolean;
    services: TServices;
}

export class Comment extends Reactive.Component<TCommentProps> {
    
    private get avatarUri() {
        const { comment: { Identity: { UserId } }, services: { cdn } } = this.props;
        const userId = Option.Serialization.deserialize(UserId);
        return (
            userId.kind === "Some"
                ? `${cdn}/users/${userId.value}.webp`
                : `${cdn}/img/webparts/comments/avatar.webp`
        )
    }

    render() {
        const { avatarUri } = this
        const { className, comment, services, isReplyingEnabled, isAllowedModeration, onReply, onRemove, ...props } = this.props;

        const dateTime = Option.Serialization.deserialize(comment.DateTime);
        const i = comment.Identity;

        return (
            <article className={classNames("Comment", className)} {...props}>
                <aside>
                    <img src={avatarUri} alt={comment.Nick} />
                </aside>
                <main className="comment-info">
                    <header className="name">
                        <div className="top"><h3 className={classNames({ Admin: i.IsAdmin })}>{comment.Nick}</h3>{!i.IsAdmin && <> <small>{i.IP}</small></>} </div>
                        {dateTime.kind === "Some" && <span>{DateFormat.asDateTime(dateTime.value)}</span>}
                        {isReplyingEnabled &&
                            <a href="javascript:void(0)" onClick={() => this.events.onReply(comment)}> Reagovať </a>
                        }
                        {isAllowedModeration &&
                            <a href="javascript:void(0)" onClick={() => this.events.onRemove(comment)}> Zmazať </a>
                        }
                    </header>
                    <main
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(comment.ContentHtml, {
                                KEEP_CONTENT: false,
                            }),
                        }}
                    />
                </main>
            </article>
        )
    }
}