import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as $ from "jquery";
import { Comments } from '@components/Comments';
import { CommentManager } from '@components/Comments/Core/CommentManager';
import { TApplication } from '@core/Application';
import { DomModule } from '../../Core/DomModule/DomModule';

type TCommentModuleProps = {
    application: TApplication;
}

export class CommentModule extends DomModule {
    protected readonly commentsSections = this.getCommentsSections();
    protected readonly commentManager: CommentManager;

    constructor(protected readonly props: TCommentModuleProps) {
        super();
        const { application: { services, storage, userSession } } = props;
        this.commentManager = new CommentManager({
            coreService: services.core,
            storage,
            userSession           
        });
    }

    protected getCommentsSections() {
        return document.querySelectorAll(".comments-section");
    }

    init = () => {
        const { application: { services, userSession } } = this.props;
        this.commentsSections.forEach(section => {
            const container = section as HTMLDivElement;
            const { key } = container.dataset;
            if (key) {
                ReactDOM.render(
                    <Comments
                        commentsKey={key}
                        commentManager={this.commentManager}
                        services={services}
                        isReplyingEnabled={false}
                        isAllowedModeration={userSession.isAdmin}
                    />,
                    container
                );
            }
        });
    }
}