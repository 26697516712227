import { TComment, Comment } from '../Model/Entities/TComment';
import { TOption, Option } from '@lib/Core/Option';
import { ICommentManager, TCommentPredicate } from './ICommentManager';
import { Json } from '@lib/Core';
import { TPersonalData } from '../Model/Entities/TPersonalData';
import { Application, TUserSession } from '@core/Application';

type TCommentManagerProps = {
    coreService: string;
    storage: Storage;
    userSession: TUserSession;
}

export class CommentManager implements ICommentManager {

    private readonly serviceUri: string;

    constructor(private readonly props: TCommentManagerProps) {
        this.serviceUri = `${props.coreService}/comments`;
    }

    get userSession() { return this.props.userSession; }

    async getComments(key: string) {
        const response = await fetch(
            `${this.serviceUri}/get`, {
                method: 'POST',
                cache: 'no-cache',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ key })
            }
        );
        return response.json() as Promise<TComment[]>;
    }

    protected createNewComment(commentFormData: Partial<TComment>): TComment {
        const { userSession } = this.props;
        const emptyComment = Comment.empty();
        const userId = (
            userSession.isLoggedIn && userSession.id
                ? Option.some(parseInt(userSession.id.toString()))
                : Option.none()
        );
        return {
            ...emptyComment,
            ...commentFormData,
            Identity: {
                ...emptyComment.Identity,
                IsAdmin: userSession.isAdmin,
                UserId: Option.Serialization.serialize(userId)
            }
        };
    }

    async addComment(commentFormData: Partial<TComment>, parent?: TComment): Promise<TComment> {
        const response = await fetch(
            `${this.serviceUri}/add`, {
                method: 'POST',
                cache: 'no-cache',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(this.createNewComment(commentFormData))
            }
        );
        return response.json() as Promise<TComment>;
    }

    async removeComment(guid: string) {
        const { userSession } = this.props;
        if (userSession.isAdmin) {
            await fetch(
                `${this.serviceUri}/remove/${guid}`, {
                    method: 'DELETE',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                        ...userSession.key(),
                    }
                }
            );
        } else {
            throw "Unauthorized"
        }
    }

    tryGetPersonalData(): TOption<TPersonalData> {
        const personalData = this.props.storage.getItem('personalData');
        return Json.tryParse(personalData);
    }

    setPersonalData(personalData: TPersonalData) {
        this.props.storage.setItem('personalData', Json.stringify(personalData));
    }
}