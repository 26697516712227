import * as React from 'react';
import * as classNames from 'classnames';
import { unsafeTypeCast, Option } from '@lib/Core';
import { Reactive } from '@lib/Core.Reactive';
import { TComment, Comment } from './Model/Entities/TComment';
import { ICommentManager } from './Core/ICommentManager';
import * as R from 'ramda';

type TCommentFormProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    commentManager: ICommentManager;
    onSend: (comment: TComment) => Promise<void>;
    commentsKey: string;
    sending: boolean;
}

type TCommentFormState = TComment & {

}

export class CommentForm extends Reactive.Component<TCommentFormProps, TCommentFormState> {
    
    constructor(props: TCommentFormProps) {
        super(props);
        this.state = this.newComment();
    }

    private newComment() {
        const { commentManager, commentManager: { userSession } } = this.props;
        return {
            ...Comment.empty(),
            ...Option.toDefaultValue(commentManager.tryGetPersonalData(), {}),
            Target: this.props.commentsKey,
            ...userSession.isLoggedIn
                ? { Nick: userSession.displayName }
                : {}
        }
    }

    private handleTextChange = (name: keyof TComment, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState(unsafeTypeCast<TComment>({
            [name]: event.currentTarget.value
        }))
    }

    private handleSendClick = async () => {
        this.props.commentManager.setPersonalData({
            Nick: this.state.Nick,
            Email: this.state.Email
        });
        await this.events.onSend(this.state);
        await this.setStateAsync(this.newComment());
    }
    
    render() {
        const { className, sending, onSend, commentsKey, commentManager, ...props } = this.props;
        const { Nick, Email, Content } = this.state;
        return (
            <div className={classNames("CommentForm", className)} {...props}>
                <main className="comment-form">
                    <form id="blog-form" method="post">
                        <main className="row">
                            <div className="input-field col s12 m12 l6">
                                <input type="text" name="name" id="name_field" value={Nick} onChange={event => this.handleTextChange("Nick", event)} />
                                <label htmlFor="name_field">Tvoje Meno</label>
                            </div>
                            <div className="input-field col s12 m12 l6">
                                <input type="text" name="email" id="email_field" value={Email} onChange={event => this.handleTextChange("Email", event)} />
                                <label htmlFor="email_field">Tvoj E-mail</label>
                            </div>
                            <div className="input-field col s12 m12 l12">
                                <textarea name="message" id="message_field" className="materialize-textarea" value={Content} onChange={event => this.handleTextChange("Content", event)} />
                                <label htmlFor="message_field">Správa</label>
                            </div>
                        </main>
                        <footer>
                            {sending
                                ?
                                    <button
                                        type="button"
                                        className="btn waves-effect waves-light"
                                        disabled={true}
                                    >
                                        Odosielanie&hellip;
                                    </button>
                                :
                                    <button
                                        type="button"
                                        className="btn waves-effect waves-light"
                                        onClick={this.handleSendClick}
                                        disabled={Nick === '' || Content === ''}
                                    >
                                        Odoslať komentár
                                    </button>
                            }
                        </footer>
                    </form>
                </main>
                <footer className="alert-success">
                    <p>Tvoj komentár bol úspešne odoslaný</p>
                </footer>
            </div>
        )
    }
}