
import * as R from 'ramda';

export type nil = undefined | null;

export type nullOr<T> = T | null;
export type undefinedOr<T> = T | undefined;
export type nilOr<T> = T | nil;

export type booleanOrNull = nullOr<boolean>
export type booleanOrUndefined = undefinedOr<boolean>
export type booleanOrNil = nilOr<boolean>

export type numberOrNull = nullOr<number>
export type numberOrUndefined = undefinedOr<number>
export type numberOrNil = nilOr<number>

export type objectOrNull = nullOr<object>
export type objectOrUndefined = undefinedOr<object>
export type objectOrNil = nilOr<object>

export type stringOrNull = nullOr<string>
export type stringOrUndefined = undefinedOr<string>
export type stringOrNil = nilOr<string>

export type symbolOrNull = nullOr<symbol>
export type symbolOrUndefined = undefinedOr<symbol>
export type symbolOrNil = nilOr<symbol>

/*export type bigintOrNull = nullOr<bigint>
export type bigintOrUndefined = undefinedOr<bigint>
export type bigintOrNil = nilOr<bigint>*/

export const unsafeTypeCast = <DST_TYPE, SRC_TYPE = any>(value: SRC_TYPE): DST_TYPE => (
    value as any as DST_TYPE
);

export type TAnyKeyValue<V = any> = {
    [key: string]: V;
}

export type TKeyValuePairs<V = any> = {
    key: string;
    value: V;
}

export module AnyKeyValue {
    export const toKeyValuePairs = <V>(keyValue: TAnyKeyValue<V>) => (
        R.toPairs(keyValue).map(([key, value]) => ({ key, value })) as TKeyValuePairs<V>[]
    )
}
type Diff<T extends string, U extends string> = ({[P in T]: P } & {[P in U]: never } & { [x: string]: never })[T]
//@ts-ignore
export type Omit<T, K extends keyof T> = Pick<T, Diff<keyof T, K>>

export type TGuid = string & { kind: "guid" };
export type TUuid = TGuid;

export module Guid {

    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }

    export function newGuid() {
        return (s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()) as TGuid;
    }

    export function isGuid(value: any) {
        return /^(\{{0,1}([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}\}{0,1})$/.test(value)
    }

    export const from = (guid: string) => guid as TGuid;
}

export default Guid;

export module Number {
    export const isFloat = (n: number) => n === +n && n !== (n | 0)
    export const isInteger = (n: number) => n === +n && n === (n | 0)
}

export module PlainObject {
    export const withDefaults = <T, D>(input: T, defaults: D): T & D => Object.assign(defaults, input);

    export const isPlainObject = (obj: any): obj is Object => (
        obj && obj.constructor === Object || false
    );
}

export module Value {
    export function parseNumber(value: any, defaultValue: any = null) {
        const result = parseFloat(value);
        return isNaN(result) ? defaultValue : result;
    }

    export function parseBool(value: string | boolean | number): boolean {
        switch (typeof value) {
            case 'string': return value === 'true' || value === '1';
            case 'number': return value === 1;
            case 'boolean': return value as boolean;
            default: {
                throw "parseBool: Unhandled value type"
            }
        }
    }

    export function isDateTime(value: any) {
        return /^([0-9]{2,4})-([0-1][0-9])-([0-3][0-9])(?:( [0-2][0-9]):([0-5][0-9]):([0-5][0-9]))?$/.test(value)
    }

    export const isGuid = Guid.isGuid;

    export const isPlainObject = PlainObject.isPlainObject;
}

export module Arr {
    export const flat = <T = any>(arr: T[], d = 1) => (
        d > 0
            ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flat<T>(val, d - 1) : val), [])
            : arr.slice()
    );
}