import { Option } from "@lib/Core"

export type TComment =
    {
        Id: string;
        Nick: string;
        Email: string;
        DateTime: Option.Serialization.TSerializedOption<string>;
        Content: string;
        ContentHtml: string;
        Identity: TIdentity
        ParentId: Option.Serialization.TSerializedOption<number>;
        Replies: TComment[];
        Target: string;
    }

export type TIdentity =
    {
        IP: string;
        Host: string;
        UserAgent: string;
        UserId: Option.Serialization.TSerializedOption<number>;
        IsAdmin: boolean;
    }

export module Comment {
    export function empty(): TComment {
        return {
            Id: '',
            Nick: '',
            Email: '',
            Content: '',
            ContentHtml: '',
            Identity: {
                IP: '',
                Host: '',
                UserAgent: '',
                UserId: null,
                IsAdmin: false
            },
            DateTime: null,
            ParentId: null,
            Replies: [],
            Target: ''
        }
    }
}