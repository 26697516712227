import { TProductData } from '@components/Portfolio/Model/Entities/TProductData';
import { CookieStorage } from 'cookie-storage';
import { TUserSession, UserSessionFactory } from './User';
export * from './User';
const global = window as any;
const { App } = global;
const { userSession, ...context } = JSON.parse(atob(App.data.context));

export const Application: TApplication = {
    ...App,
    ...context,
    window,
    global,
    storage: (
        localStorage === undefined
            ? new CookieStorage({ path: '/', sameSite: 'Strict' })
            : localStorage
    ),
    cookieStorage: new CookieStorage({ path: '/', sameSite: 'Strict' }),
    userSession: UserSessionFactory.createSession(userSession)
}

export type TApplication = Readonly<{
    root: string;
    data: TApplicationData;
    window: Window;
    global: any;
    formatters: Map<number, TFormatter>;
    services: TServices;
    storage: Storage;
    cookieStorage: CookieStorage;
    userSession: TUserSession;
}>

export type TApplicationData = {
    portfolio?: TProductData[];
}

export type TFormatter = Readonly<{
    id: string;
    name: string;
    api: string;
    uri: string;
    full_layout: string;
}>

export type TServices = Readonly<{
    core: string;
    cdn: string;
    mycdn: string;
}>