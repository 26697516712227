import { DomModule } from '@core/DomModule';
import { Application } from '@core/Application';
import dynamicLoader from '@lib/DynamicLoader';

export type TStartUpModuleProps = {
    readonly mode: "web" | "admin";
    readonly window: Window;
}

export class StartUpModule extends DomModule {
    constructor(protected readonly props: TStartUpModuleProps) {
        super();
    }

    startWeb() {
    }

    startAdmin() {
    }

    startAll() {
        dynamicLoader.addJSOnce(`/bower_components/fancybox/dist/jquery.fancybox.min.js`, () => {
            const { fancybox: { defaults } } = $ as any;
            defaults.hash = false;
            defaults.animationEffect = "zoom";
            defaults.transitionEffect = "slide";
        });
        dynamicLoader.addCSSOnce(`/bower_components/fancybox/dist/jquery.fancybox.min.css`);
    }

    init() {
        this.props.window.onload = () => {
            switch (this.props.mode) {
                case "web": {
                    this.startWeb();
                    this.startAll();
                    break;
                }
                case "admin": {
                    this.startAdmin();
                    this.startAll();
                    break;
                }
            }
        }
    }
}