import * as stdio from 'extsprintf';

export module Age {
    export function fromBirthday(birthday: Date) {
        var ageDifMs = Date.now() - birthday.getTime();
        var ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    export function fromBirthdayString(birthday: string) {
        const i = birthday.split('.');
        const date = new Date(parseInt(i[2]), parseInt(i[1]) - 1, parseInt(i[0]), 0, 0, 0);
        return fromBirthday(date);
    }

    export function fromBirthdayISOString(birthday: string) {
        const i = birthday.split('-');
        const date = new Date(parseInt(i[0]), parseInt(i[1]) - 1, parseInt(i[2]), 0, 0, 0);
        return fromBirthday(date);
    }
}

export module BirthNumber {

    export function toPersonalData(birthNumber: string | number) {
        const bn = birthNumber.toString();
        const [ year, monthAndGender, day ] = [[0, 2], [2, 4], [4, 6]].map(([from, to]) => parseInt(bn.substring(from, to)))
        const [ gender, month ] = monthAndGender > 50 ? ["female", monthAndGender - 50] : ["male", monthAndGender];
        return {
            year: (bn.length === 9 || year > 53 ? 1900 : 2000) + year,
            month,
            day,
            gender
        }
    }

    export function toDateOfBirthISOString(birthNumber: string | number) {
        const { year, month, day } = toPersonalData(birthNumber);
        return stdio.sprintf("%04d-%02d-%02d", year, month, day);
    }
    
}
