export module Uri {

    const  chars = { 'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'ý': 'y', 'č': 'c', 'ď': 'd', 'ť': 't', 'ň': 'n', 'ľ': 'l', 'š': 's', 'ž': 'z', 'ĺ': 'l', 'ŕ': 'r', 'ä': 'a', 'ô': 'o', 'ů': 'u', 'ě': 'e', 'ř': 'r' };
        
    export function create(input: string)
    {
        input = input.toLowerCase();
        let arr = []; var len = input.length;
        for (let i = 0; i < len; i++)
        {
            let c1 = input.charAt(i);
            let c2 = chars[c1];
            (arr as any)[i] = typeof c2 == 'undefined' ? c1 : c2;
        }
        return arr.join("").replace(/[^a-z0-9_]+/g, '-').replace(/^-|-$/g, '');
    }

    export function toDomain(url: string) {
        url = url.endsWith('/') ? url.substring(0, url.length - 1) : url;
        const beginPattern = '://';
        const beginPatternPosition = url.indexOf(beginPattern);
        if (beginPatternPosition !== -1) {
            url = url.substring(beginPatternPosition + beginPattern.length);
        }
        const slashPosition = url.indexOf('/');
        return slashPosition === -1 ? url : url.substr(0, slashPosition);
    }

}