import * as React from 'react';
import { Product } from './Product';
import { TProductData, TPortfolioCategoryData } from './Model';
import * as R from 'ramda';

type TPortfolio = {
    caption: React.ReactNode;
    note?: React.ReactNode;
    products: TProductData[];
}

export class Portfolio extends React.Component<TPortfolio> {

    render() {
        const { caption, note, products } = this.props;
        
        const portfolioCategories = (
            R.uniqBy(i => i.id, products.map(p => ({
                id: p.cat_id,
                name: p.cat_name,
                uri: p.cat_uri
            })))
        );

        return (
            <section className="Portfolio">
                <header className="row">
                    <div className="col s12 m12 l12">
                        <h5 className="grey-text text-darken-2 left-align">{caption}</h5>
                        {note && <p>{note}</p>}
                    </div>
                </header>
                <header className="row">
                    <div className="col s12 m12 l12">
                        <ul className="tabs filter z-depth-1">
                            <li className="tab">
                                <a className="active" data-filter=".all">All</a>
                            </li>
                            {portfolioCategories.map(({ name, uri }) => (
                                <li className="tab" key={uri}>
                                    <a data-filter={`.${uri}`}>{name}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </header>
                <main className="row">
                    {products.map((product, index) => <Product key={`${product.uri}-${product.id}`} index={index} {...product} />)}
                </main>
            </section>
        );
    }

}