import './assets/ContactForm.styl';

import * as React from 'react';
import * as R from 'ramda';

type TContactFormProps = {
    action: string;
}

export type TContactFormState = {
    name: string;
    email: string;
    subject: string;
    message: string;
}

export type TContactFormFiled = keyof TContactFormState;

const initialState: TContactFormState = {
    name: "",
    email: "",
    subject: "",
    message: ""
}

export class ContactForm extends React.Component<TContactFormProps, TContactFormState> {

    private formRef = React.createRef<HTMLFormElement>();
    private allFields = ['name', 'email', 'subject', 'message'];

    state: TContactFormState = { ...initialState };

    private handleChangeInput = (field: TContactFormFiled) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ [field]: event.currentTarget.value } as TContactFormState)
    }

    private handleSubmited = () => {
        this.setState({ ...initialState });
    }

    private handleButtonClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        const { allFields, props: { action }, state } = this;
        const { current: formElement } = this.formRef;
        if (formElement) {
            let submit = true;
            for (let field of allFields) {
                if (this.state[field] === '') {
                    const element = field === 'message' ? 'textarea' : 'input';
                    const fieldElement = formElement.querySelector(`${element}[name="${field}"]`) as (
                        HTMLInputElement |
                        HTMLTextAreaElement
                    );
                    submit = false;
                    fieldElement.focus();
                    return;
                }
            }
            if (submit) {
                $.ajax({
                    type: "POST",
                    url: action,
                    data: state,
                    success: () => this.handleSubmited(),
                    dataType: "JSON"
                });
            }
        }
    }

    render() {
        const { props: { action }, state } = this;

        return (
            <section className="ContactForm card-panel custom-size">
                <div className="contact-form">
                    <form method="POST" action={action} ref={this.formRef}>
                        <h5>Napíš správu:</h5>
                        <div className="row">
                            <div className="input-field col s12">
                                <input type="text" name="name" onChange={this.handleChangeInput("name")} value={state.name} />
                                <label htmlFor="name">Vaše meno</label>
                            </div>
                            <div className="input-field col s12">
                                <input type="text" name="email" onChange={this.handleChangeInput("email")} value={state.email} />
                                <label htmlFor="email">Váš Email</label>
                            </div>
                            <div className="input-field col s12">
                                <input type="text" name="subject" onChange={this.handleChangeInput("subject")} value={state.subject} />
                                <label htmlFor="subject">Predmet</label>
                            </div>
                            <div className="input-field col s12">
                                <textarea name="message" className="MessageField materialize-textarea" onChange={this.handleChangeInput("message")} value={state.message} />
                                <label htmlFor="message">Správa</label>
                            </div>
                        </div>
                        <a className="btn waves-effect waves-light" href="javascript:void(0)" onClick={this.handleButtonClick}>Odoslať správu</a>
                    </form>
                </div>
            </section>
        );
    }

}