export class LazyExpr<T> {
	private valueCreated = false;
	private result?: T;

	constructor(readonly expr: () => T) {}

	get isValueCreated() { return this.valueCreated; }

	get value() { return this.isValueCreated ? this.result as T : this.force(); }

	force() {
        this.result = this.expr();
        this.valueCreated = true;
		return this.result as T;
	}
}