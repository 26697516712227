import 'ts-polyfill/lib/es2016-array-include';
import 'ts-polyfill/lib/es2017-object';
import 'ts-polyfill/lib/es2017-string';
import 'ts-polyfill/lib/es2018-async-iterable';   // for-await-of
import 'ts-polyfill/lib/es2018-promise';
import 'ts-polyfill/lib/es2019-array';
import 'ts-polyfill/lib/es2019-object';
import 'ts-polyfill/lib/es2019-string';
import 'ts-polyfill/lib/es2020-string';
import { DomModules } from "@core/DomModule";
import { Application } from '@core/Application';

DomModules.init([
    ['Date'],
    ['SyntaxHighlighter'],
    ['Tag'],
    ['Portfolio'],
    ['Contact'],
    ['StartUp', {
        mode: "web",
        window: Application.window
    }],
    ['Print'],
    ['Comment', {
        application: Application
    }]
]);