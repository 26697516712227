import * as $ from "jquery";
import { DomModule } from '@core/DomModule';

export class AutocompleteOffModule extends DomModule {
    init() {
        if (document.querySelectorAll) {
            const autocompleteOffElements = document.querySelectorAll('.autocomplete-off');
            setTimeout(() => {
                autocompleteOffElements.forEach(element => (element as HTMLElement).removeAttribute('readonly'));
            }, 500);
        }
    }
}