import './assets/Comments.styl';

import * as React from 'react';
import * as classNames from 'classnames';
import { CommentForm } from './CommentForm';
import { TComment } from './Model/Entities/TComment';
import { Reactive } from '@lib/Core.Reactive';
import { Comment } from './Comment';
import { ICommentManager } from './Core/ICommentManager';
import { TServices } from '../../Core/Application';
import * as R from 'ramda';

type TCommentsProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    commentManager: ICommentManager;
    commentsKey: string;
    services: TServices;
    isReplyingEnabled: boolean;
    isAllowedModeration: boolean;
}

type TCommentsState = {
    comments: TComment[] | null;
    replyTarget: TComment | null;
    sending: boolean;
}

export class Comments extends Reactive.Component<TCommentsProps, TCommentsState> {
    state: TCommentsState;

    constructor(props: TCommentsProps) {
        super(props);
        this.state = {
            comments: null,
            replyTarget: null,
            sending: false
        }
        this.fetchComments(props.commentsKey);
    }

    private fetchComments = async (commentsKey: string) => {
        const { commentManager } = this.props;
        this.setState({
            comments: await commentManager.getComments(commentsKey)
        });
    }

    private handleReplyClick = (comment: TComment) => {
        this.setState({ replyTarget: comment });
    }

    private handleSend = async (comment: TComment) => {
        const { comments } = this.state;
        const { commentManager } = this.props;
        await this.setStateAsync({ sending: true });
        const newComment = await commentManager.addComment(comment);
        await this.setStateAsync({
            comments: [newComment, ...(comments || [])],
            sending: false
        });
    }

    private handleRemoveClick = async (comment: TComment) => {
        const { comments } = this.state;
        const { commentManager } = this.props;
        const uuid = comment.Id;
        try {
            await commentManager.removeComment(uuid);
            await this.setStateAsync({
                comments: (comments || []).filter(comment => comment.Id !== uuid)
            });
        } catch (e) {

        }
    }

    render() {
        const { className, services, commentsKey, commentManager, isReplyingEnabled, isAllowedModeration, ...props } = this.props;
        const { comments, sending } = this.state;

        return (
            <section className={classNames("Comments", className)} {...props}>
                <main className="col-md-12">
                    <header>
                        <h3 className="subtitle">Komentáre</h3>
                    </header>
                    <CommentForm
                        onSend={this.handleSend}
                        commentsKey={commentsKey}
                        sending={sending}
                        commentManager={commentManager}
                    />
                    <main className="post-comment">
                        {comments
                            ?
                                <>
                                    {comments.map((comment, key) => (
                                        <Comment
                                            key={`${comment.Id}${key}`}
                                            comment={comment}
                                            onReply={this.handleReplyClick}
                                            onRemove={this.handleRemoveClick}
                                            services={services}
                                            isReplyingEnabled={isReplyingEnabled}
                                            isAllowedModeration={isAllowedModeration}
                                        />
                                    ))}
                                </>
                            :
                                <div className="progress">
                                    <div className="indeterminate"></div>
                                </div>
                        }
                    </main>
                </main>
            </section>
        )
    }
}