import * as R from 'ramda';
import * as React from 'react';

export module ReactAsync {

    export function setState<K extends keyof S, P = {}, S = {}, SS = any>(state: ((prevState: Readonly<S>, props: P) => (Pick<S, K> | S)) | (Pick<S, K> | S), component: React.Component<P, S, SS>): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                component.setState(state, resolve);
            } catch (e) {
                reject(e);
            }
        });
    }

    export function fillState<K extends keyof S, P = {}, S = {}, SS = any>(filler: (value: any, key: string) => any, component: React.Component<P, S>) {
        return ReactAsync.setState<K, P, S>(
            R.mapObjIndexed(filler, component.state) as any,
            component
        );
    }

    export function clearState<K extends keyof S, P = {}, S = {}, SS = any>(component: React.Component<P, S>) {
        return ReactAsync.fillState<K, P, S>(() => '', component);
    }
}