import * as React from 'react';
import { ContactForm } from '@components/ContactForm';
import { SingleReactModule } from '../../Core/DomModule/ReactModule';
import { Application } from '@core/Application';

export class ContactModule extends SingleReactModule {

    constructor() {
        super({ id: "ContactModule" })
    }

    render() {
        return (
            <ContactForm
                action={`${Application.root}/kontakt/submit-json`}
            />
        )
    }
}