
export module RelativeDate {

    export const prettify = (relativeDate: string) => {
        switch (relativeDate) {
            case "pred dňom": {
                return "včera";
            }
            case "pred 2 dňami": {
                return "predvčerom"
            }
            default: {
                return relativeDate;
            }
        }
    }
    
}
