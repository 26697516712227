export type TSome<T> = {
	value: T;
	kind: 'Some';
}

export type TNone = {
	kind: 'None';
}

export type TOption<T> = TSome<T> | TNone;

export module Option {
	export function some<T>(value: T): TSome<T> {
		return { value, kind: "Some" }
	}

	export function none(): TNone {
		return { kind: "None" }
	}

	export function fromNilValue<T>(value: T): TOption<T> {
		return (
			value === null || value === undefined
				? none()
				: some<T>(value)
		);
	}

	export function toDefaultValue<T, D = any>(option: TOption<T>, defaultValue: D): T | D {
		return (
			option.kind === "Some"
				? option.value
				: defaultValue as D
		)
	}
	
	export function map<S, D>(option: TOption<S>, mapper: (value: S) => D): TOption<D> {
		return (
			option.kind === "Some"
				? { value: mapper(option.value), kind: "Some" }
				: { kind: "None" }
		);
	}

	export function isSome<T>(option: TOption<T>) {
		return option.kind === "Some";
	}

	export function isNone<T>(option: TOption<T>) {
		return option.kind === "None";
	}

	export function getValue<T>(option: TOption<T>) {
		if (option.kind === "Some") {
			return option.value;
		} else {
			throw "The None type has not a value";
		}
	}

	export module Serialization {
		
		export type TSerializedOption<T> = null | {
			Case: "Some",
			Fields: T[]
		}

		export function serialize<T>(option: TOption<T>): TSerializedOption<T> {
			return (
				option.kind === "Some"
					? { Case: option.kind, Fields: [option.value] }
					: null
			);
		}
	
		export function deserialize<T>(serialized: TSerializedOption<T>): TOption<T> {
			return (
				serialized === null
					? { kind: "None" }
					: { kind: "Some", value: serialized.Fields[0] }
			);
		}
	}
}