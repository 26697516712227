import { DomModule } from '@core/DomModule';
import * as highlightJS from 'highlight.js/lib/highlight';
import 'highlight.js/styles/vs2015.css';
import './assets/SyntaxHighlighterModule.styl';

type TModuleInfo = {
    moduleName: string;
    caption: string;
}

type TLanguageInfo = TModuleInfo & {
    element: HTMLElement;
    languageName: string;
}

export class SyntaxHighlighterModule extends DomModule {

    protected moduleNames = new Map<string, string | TModuleInfo>([
        ["html", "htmlbars"],
        ["html5", "htmlbars"],
        ["xhtml", "htmlbars"],
        ["js", "javascript"],
        ["terminal", "powershell"],
        ["terminal", "powershell"],
        ["fsharp", {
            caption: "F#",
            moduleName: "fsharp"
        }],
        ["csharp", {
            caption: "C#",
            moduleName: "cs"
        }]
    ]);

    protected registeredLanguages = new Set<string>();

    protected langClassPrefix = 'language-';
    
    protected updateLanguageContainer = ({ element, languageName, moduleName, caption }: TLanguageInfo) => {
        const captionElement = document.createElement("small"); 
        captionElement.appendChild(document.createTextNode(caption));
        if (languageName !== moduleName) {
            element.className = this.langClassPrefix + moduleName;
        }
        element.appendChild(captionElement);
    }

    protected registerLanguage = (moduleName: string) => {
        const language = require(`highlight.js/lib/languages/${moduleName}`);
        highlightJS.registerLanguage(moduleName, language);
    }

    protected tryLazyRegisterLanguage = (moduleName: string) => {
        if (!this.registeredLanguages.has(moduleName)) {
            try {
                this.registerLanguage(moduleName);
            } catch (e) {
            }
            this.registeredLanguages.add(moduleName);
        }
    }

    protected registerRelevantLanguages = (highlightJS) => {
        document.querySelectorAll('pre code').forEach(element => {
            const prefixedName = element.className.split(' ').find(c => c.startsWith(this.langClassPrefix));
            if (typeof prefixedName === "string") {
                const names = prefixedName.split('-');
                if (names.length === 2) {
                    const languageName = names[1];
                    const item = this.moduleNames.get(languageName) || languageName;
                    const moduleInfo = (
                        typeof item === "string"
                            ? { moduleName: item, caption: item }
                            : item
                    );
                    this.updateLanguageContainer({
                        element: element as HTMLElement,
                        languageName,
                        ...moduleInfo
                    });
                    this.tryLazyRegisterLanguage(moduleInfo.moduleName);
                }
            }
        });
    }

    init() {
        this.registerRelevantLanguages(highlightJS);
        highlightJS.initHighlightingOnLoad();
    }
}