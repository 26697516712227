export module DIRECTIVE {
    let directives = new Map<string, any>();

    export function DEFINE(directive: string, value: any = true) {
        directives.set(directive, value);
    }

    export function UNDEF(directive: string) {
        directives.delete(directive);
    }

    export function IF_DEFINED(directive: string) {
        return directives.has(directive);
    }

    export function IF_NOT_DEFINED(directive: string) {
        return !directives.has(directive);
    }

    export function IF_EQUALS(directive: string, value: any = true) {
        return directives.get(directive) === value;
    }

    export function IF_NOT_EQUALS(directive: string, value: any = true) {
        return directives.get(directive) !== value;
    }
    
    export function ALL() {
        return directives;
    }

    export function IF<R>(directive: string, action: () => R) {
        if (IF_DEFINED(directive)) {
            return action();
        }
    }

    export function IF_NOT<R>(directive: string, action: () => R) {
        if (IF_NOT_DEFINED(directive)) {
            return action();
        }
    }
}